import * as React from 'react'

import '../components/variables.css'
import '../components/global.css'
import * as styles from './not-found.module.css'
import UnpluggedIcon from '../components/assets/unplugged.svg'

const NotFoundPage = () => (
  <div className={styles.container}>
    <UnpluggedIcon />
    <h1>Oops! Looks like this page is off the grid.</h1>
    <p>
      Check out our home page to discover how you can start earning rewards with
      your device.
    </p>
    <a href={'/'} className={styles.button}>
      Go to home
    </a>
  </div>
)

export default NotFoundPage
